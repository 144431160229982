import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import i18n from '../Traduction/i18n';
/*  importation component */
import { WorkBox, SimpleWorkBox } from '../WorkBox/WorkBox';
import AppearOnScroll from '../AppearOnScroll/AppearOnScroll';
import NavbarPoints from '../NavbarPoints/NavbarPoints';

/* importation images */
import UnoImage from '../../Assets/images/uno1280.jpg';
import ReactLaravel from '../../Assets/images/ReactLaravel.jpg';
import MarioGame from '../../Assets/images/mariogame.jpg';
import MediaTwitter from '../../Assets/images/Twittermedia.jpg';
import SearchEngine from '../../Assets/images/searchengine.jpg';
import ImageMap from '../../Assets/images/ImageMap.jpg';
import ToDoList from '../../Assets/images/ToDOT.jpg';
import Tetris from '../../Assets/images/Tetris.jpg';
import SmartContract from '../../Assets/images/SmartContract.jpg';
import NeuralNetwork from '../../Assets/images/NeuralNetwork.jpg';
import Github from '../../Assets/images/Github.jpg';

function Works() {
    /* creation de références */

    const numberofWorks = 11;
    const { t } = useTranslation();
    const leftRef = useRef(null);
    const rightRef = useRef(null);
    const leftRef2 = useRef(null);
    const rightRef2 = useRef(null);
    const leftRef3 = useRef(null);
    const rightRef3 = useRef(null);
    const leftRef4 = useRef(null);
    const rightRef4 = useRef(null);
    const leftRef5 = useRef(null);
    const rightRef5 = useRef(null);
    const leftRef6 = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const elementWidth = leftRef.current.offsetWidth / 2;
        const halfScreenWidth = window.innerWidth / 2;

        /* fonction d'animation */

        function leftToRightEffect(x) {
            gsap.to(x.current, {
                scrollTrigger: {
                    trigger: x.current,
                    start: 'center center',
                    end: 'top top',
                    scrub: true,
                    pin: true,
                    anticipatePin: 1,
                },
                //on met au centre de l'écran
                x: halfScreenWidth + elementWidth,
            });
        }

        function rightToLeftEffect(x) {
            gsap.to(x.current, {
                scrollTrigger: {
                    trigger: x.current,
                    start: 'center center',
                    end: 'top top',
                    scrub: true,
                    pin: true,
                    anticipatePin: 1,
                },
                x: (halfScreenWidth + elementWidth) * -1,
            });
        }




        /* application de nos animations au refs */
        leftToRightEffect(leftRef);

        leftToRightEffect(rightRef);

        leftToRightEffect(leftRef2);

        leftToRightEffect(rightRef2);

        leftToRightEffect(leftRef3);

        leftToRightEffect(rightRef3);

        leftToRightEffect(leftRef4);

        leftToRightEffect(rightRef4);

        leftToRightEffect(leftRef5);

        leftToRightEffect(rightRef5);

        leftToRightEffect(leftRef6);

        /*         leftToRightEffect(leftRef);

        rightToLeftEffect(rightRef);

        leftToRightEffect(leftRef2);

        rightToLeftEffect(rightRef2);

        leftToRightEffect(leftRef3);

        rightToLeftEffect(rightRef3);

        leftToRightEffect(leftRef4);

        rightToLeftEffect(rightRef4);

        leftToRightEffect(leftRef5); */



        return () => {
            gsap.utils.toArray('.box').forEach((box) => {
                gsap.killTweensOf(box);
            });

        };


    }, []);



    return (
        <div className='works-page'>

            <div className='works-landing'>
                <h1>{t('Projets')}</h1>
            </div>
            <div class="arrow-down">
                <div class="left"></div>
                <div class="right"></div>
            </div>

            <AppearOnScroll>
                <NavbarPoints RefList={[leftRef, rightRef, leftRef2, rightRef2, leftRef3, rightRef3, leftRef4, rightRef4, leftRef5, rightRef5, leftRef6]} TitlesList={['Mcirosoft', "Webcam Run", "Media Twitter", "Mysearch", "Visualise image", "Uno", "Ticket Gestion", t("Tetris_Plateau"), "Smart contract", "Neural networks", "Github"]} />


            </AppearOnScroll>

            {/* 100+ numberofWorks * 100 */}
            <div className='works' style={{ height: `${(numberofWorks * 100)}vh` }}>

                <div className='left-box'>
                    <div className='box' ref={leftRef}>

                        <WorkBox
                            title={t('Mcirosoft')}
                            description={t('description_Mcirosoft')}
                            image={ReactLaravel}
                            // 'technologies_Mcirosoft' est un tableau de string
                            technologies={t('technologies_Mcirosoft').split(',')}
                            link="https://github.com/SpinHit/Mcirosoft-Template-Website"
                        /></div>
                </div>

                <div className='left-box'>
                    <div className='box' ref={rightRef}>                        <WorkBox
                        title={t('Webcam_Run')}
                        description={t('description_Webcam_Run')}
                        image={MarioGame}
                        technologies={t('technologies_Webcam_Run').split(',')}
                        link="https://github.com/SpinHit/Webcam-Run"
                    /></div>
                </div>

                <div className='left-box'>
                    <div className='box' ref={leftRef2}>                        <WorkBox
                        title={t('MediaTwitter')}
                        description={t('description_MediaTwitter')}
                        image={MediaTwitter}
                        technologies={t('technologies_MediaTwitter').split(',')}
                        link="https://github.com/SpinHit/MediaTwitter"

                    /></div>
                </div>

                <div className='left-box'>
                    <div className='box' ref={rightRef2}>
                        <WorkBox
                            title={t('Mysearch')}
                            description={t('description_Mysearch')}
                            image={SearchEngine}
                            technologies={t('technologies_Mysearch').split(',')}
                            link="https://github.com/SpinHit/Mysearch"
                        />
                    </div>
                </div>

                <div className='left-box'>
                    <div className='box' ref={leftRef3}>
                        <WorkBox
                            title={t('Visualise_Image')}
                            description={t('description_Visualise_Image')}
                            image={ImageMap}
                            technologies={t('technologies_Visualise_Image').split(',')}
                            link="https://github.com/SpinHit/Visualise-Image-App"
                        />


                    </div>
                </div>

                <div className='left-box'>
                    <div className='box' ref={rightRef3}>
                        <WorkBox
                            title={t('Uno')}
                            description={t('description_Uno')}
                            image={UnoImage}
                            technologies={t('technologies_Uno').split(',')}
                            link="https://github.com/SpinHit/Jeu-Uno"
                        />
                    </div>
                </div>

                <div className='left-box'>
                    <div className='box' ref={leftRef4}>
                        <WorkBox
                            title={t('Angular_TicketGestion')}
                            description={t('description_Angular_TicketGestion')}
                            image={ToDoList}
                            technologies={t('technologies_Angular_TicketGestion').split(',')}
                            link="https://github.com/SpinHit/Angular-TicketGestion"
                        />


                    </div>
                </div>

                <div className='left-box'>
                    <div className='box' ref={rightRef4}>
                        <WorkBox
                            title={t('Tetris_Plateau')}
                            description={t('description_Tetris_Plateau')}
                            image={Tetris}
                            technologies={t('technologies_Tetris_Plateau').split(',')}
                            link="https://github.com/SpinHit/Tetris-Plateau"
                        />
                    </div>
                </div>

                <div className='left-box'>
                    <div className='box' ref={leftRef5}>
                        <WorkBox
                            title={t('Gouvernement_Manager')}
                            description={t('description_Gouvernement_Manager')}
                            image={SmartContract}
                            technologies={t('technologies_Gouvernement_Manager').split(',')}
                            link="https://github.com/SpinHit/SmartContract-Gouvernement-Manager-ETH"
                        />


                    </div>
                </div>

                <div className='left-box'>
                    <div className='box' ref={rightRef5}>
                        <WorkBox
                            title={t('Projets_Reseau_de_neurones')}
                            description={t('description_Projets_Reseau_de_neurones')}
                            image={NeuralNetwork}
                            technologies={t('technologies_Projets_Reseau_de_neurones').split(',')}
                            link="https://github.com/SpinHit/Projets-I.A-Neural-Networks"

                        />
                    </div>
                </div>


                <div className='left-box'>
                    <div className='box' ref={leftRef6}>
                        <SimpleWorkBox style={{ width: 'background-color: red' }}
                            title={t('Voir tous mes projets')}
                            image={Github}
                            link="https://github.com/SpinHit"
                        />

                    </div>

                </div>

            </div>

        </div>


    )
}

export default Works;