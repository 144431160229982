import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import './Timeline.scss';
import logoToshiba from '../../Assets/Logo/Toshiba.png';
import logoSymbiot from '../../Assets/Logo/Symbiot.svg';
import logoParis8 from '../../Assets/Logo/Paris8.png';
import { useTranslation } from 'react-i18next';
import i18n from '../Traduction/i18n';

const TimelineAboutMe = () => {
    const { t } = useTranslation();

    return (
        <section className='qualification'>
            <h2 className="section__title">
                <h3>{t('Qualifications')}</h3>
            </h2>
            <span className="section__subtitle">{t('- Mon parcours personnel -')}</span>

            <VerticalTimeline>
                {/* Alternance Toshiba TFIS */}
                <VerticalTimelineElement
                    date="2022 - 2023"
                    iconStyle={{ background: 'black', color: 'black' }}
                >
                    <h3 className="qualification__title"> <img src={logoToshiba} alt="Logo Toshiba" className="qualification__logo" style={{ height: '10px' }} /> Toshiba TFIS</h3>
                    <h4 className="qualification__subtitle">{t('Ingénieur développeur logiciel polyvalent (alternance)')}</h4>
                    <p>{t('1 an')}</p>
                </VerticalTimelineElement>

                {/* Stage Symbiot */}
                <VerticalTimelineElement
                    date="2022"
                    iconStyle={{ background: 'black', color: 'black' }}
                >
                    <h3 className="qualification__title"> <img src={logoSymbiot} alt="Logo Symbiot" className="qualification__logo" style={{ height: '20px' }} /> Symbiot</h3>
                    <h4 className="qualification__subtitle">{t('Développeur Web Full Stack (stage)')}</h4>
                    <p>{t('4 mois')}</p>
                </VerticalTimelineElement>

                {/* Master Informatique */}
                <VerticalTimelineElement
                    date="2021 - 2023"
                    iconStyle={{ background: 'black', color: 'black' }}
                >
                    <h3 className="qualification__title"> <img src={logoParis8} alt="Logo Paris 8" className="qualification__logo" style={{ height: '20px' }} /> {t('Master Informatique')}</h3>
                    <h4 className="qualification__subtitle">{t('Université Paris 8')}</h4>
                </VerticalTimelineElement>

                {/* Licence Informatique */}
                <VerticalTimelineElement
                    date="2018 - 2021"
                    iconStyle={{ background: 'black', color: 'black' }}
                >
                    <h3 className="qualification__title"> <img src={logoParis8} alt="Logo Paris 8" className="qualification__logo" style={{ height: '20px' }} /> {t('Licence Informatique')}</h3>
                    <h4 className="qualification__subtitle">{t('Université Paris 8')}</h4>
                </VerticalTimelineElement>
            </VerticalTimeline>

        </section>
    );
};

export default TimelineAboutMe;