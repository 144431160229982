import React from 'react';
import 'boxicons/css/boxicons.min.css';
import { useTranslation } from 'react-i18next';
import i18n from '../Traduction/i18n';



function MySkills() {
    const { t } = useTranslation();
    const skills = [
        {
            category: t('Langages de programmation'), items: [
                { name: 'JavaScript', icon: 'bxl-javascript' },
                { name: 'C/C++', icon: 'bxl-c-plus-plus' },
                { name: 'Java', icon: 'bxl-java' },


                { name: 'Racket', icon: 'bx-code' },
                { name: 'Python', icon: 'bxl-python' },
                { name: 'C#', icon: 'bxl-microsoft' }
                , // Ajout de C#

                { name: 'HTML', icon: 'bxl-html5' },
                { name: 'CSS & SASS', icon: 'bxl-css3', icon2: 'bxl-sass' }, // Couplage de CSS et SASS
            ]
        },
        {
            category: t('Frameworks & Libraries'), items: [
                { name: 'React', icon: 'bxl-react' },
                { name: 'React Native', icon: 'bxl-react' },
                { name: 'PHP', icon: 'bxl-php' },
                { name: 'Laravel', icon: 'bx-code' },
                { name: 'Node.js', icon: 'bxl-nodejs' },
                { name: '', icon: '' },
                { name: '.NET', icon: 'bxl-microsoft' },
            ]
        },
        {
            category: t('Base de données'), items: [
                { name: 'PostgreSQL', icon: 'bxl-postgresql' },
                { name: 'MySQL', icon: 'bxs-data' },
                { name: 'Cosmos DB', icon: 'bxl-microsoft' }, // Ajout de Cosmos DB
                { name: 'SQLite', icon: 'bxs-data' },
            ]
        },
        {
            category: t('Outils & Technologies'), items: [
                { name: 'Docker', icon: 'bxl-docker' },
                { name: 'Azure', icon: 'bx-cloud' },

                { name: 'GitHub', icon: 'bxl-github' },
                { name: 'GitLab', icon: 'bxl-gitlab' },
                { name: 'Portainer.io', icon: 'bx-chip' },
                { name: 'Figma', icon: 'bxl-figma' },


                { name: 'Jira', icon: 'bx-task' }, // Ajout de Jira
                { name: 'Trello', icon: 'bxl-trello' }, // Ajout de Trello
                { name: 'Postman', icon: 'bx-network-chart' },
                { name: 'Photoshop', icon: 'bxl-adobe' },
                { name: 'Sony Vegas', icon: 'bx-film' },

            ]
        },
    ];


    return (
        <section className='Myskills'>
            <h2 className="section__title">{t('Mes compétences')}</h2>
            <span className="section__subtitle">- {t('Ce que je maîtrise')} -</span>
            <div className="skills__container container grid">
                {skills.map((skillGroup, index) => (
                    <div key={index} className="skills__content">
                        <h3 className="skills__title">{skillGroup.category}</h3>
                        <ul className="skills__list">
                            {skillGroup.items.map((skill, i) => (
                                <li key={i} className="skills__item">
                                    {skill.icon ? <i className={`bx ${skill.icon} skills__icon`}></i> : null}
                                    {skill.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default MySkills;
