import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: {
                    "Projets": "Projects",
                    "Mcirosoft": "Mcirosoft",
                    "description_Mcirosoft": "This website template offers a wide variety of pages, including a home page, a service presentation, articles, an application form, a forum and much more. The front-end is hosted on Github for a smooth and fast browsing experience.",
                    "technologies_Mcirosoft": "React,SCSS,Laravel,PostgreSQL,Forum,Dashboard,Service page,Contact page,Application page,Login page,Authentication token,Automatic form,PDF downloads",
                    "Webcam_Run": "Webcam Run",
                    "description_Webcam_Run": "This game is an interactive platform game in which the player controls a character using a webcam to track head movements. He must avoid obstacles while racing against the clock to reach a final chest. The game uses a combination of Python libraries to offer an immersive and fun experience.",
                    "technologies_Webcam_Run": "Python,Youtube-API,MYSQL,Arcade,OpenCV2,Tkinter,Pyscreenrec,Tiled,Pillow,Platform game,Time trial,Webcam,Recorded gameplay,Gameplay sharing,Score sharing",
                    "MediaTwitter": "MediaTwitter",
                    "description_MediaTwitter": "Mediatwitter is a web application designed to display multimedia tweets from Twitter. It allows you to easily find media or users through an effective search bar and suggest media for future shortcuts.",
                    "technologies_MediaTwitter": "ReactJS,NodeJS,Mysql,Scss,React,Moment,Toastify,Linkify,Paginate,Hook-form,Twitter API,Axios,React-router-dom,Database,Search,Mailform",
                    "Mysearch": "Mysearch",
                    "description_Mysearch": "A web search engine developed in PHP that allows you to index URLs and search for them based on relevant keywords. The search engine uses keyword weight to rank search results.",
                    "technologies_Mysearch": "PHP,MySQL,javascript,HTML,CSS,Keyword cloud,Search engine,URL indexing,Keyword search,Display of indexing information",
                    "Visualise_Image": "Visualise Image",
                    "description_Visualise_Image": "A project allowing to visualize images through a Google map and an interactive timeline using the location and shooting date metadata stored in a MYSQL database.",
                    "technologies_Visualise_Image": "PHP,HTML,CSS,JavaScript,Google Maps API,vis.js library,MYSQL database,exif library",
                    "Uno": "Uno",
                    "description_Uno": "This Uno command line game was developed in C language. It uses the basic concepts of C language, such as arrays, structures, pointers, loops, and conditions to simulate the game.",
                    "technologies_Uno": "C,Card game,Multiplayer card game,Terminal,Local network card game",
                    "Angular_TicketGestion": "Angular-TicketGestion",
                    "description_Tetris_Plateau": "Tetris is a puzzle game in which the player must place blocks of different shapes into a matrix to form complete lines. The goal is to keep the board as long as possible not full.",
                    "technologies_Tetris_Plateau": "C++,Tetris,Board,Puzzle game",
                    "Tetris_Plateau": "Tetris-Board",
                    "Gouvernement_Manager": "Government Manager",
                    "description_Gouvernement_Manager": "This contract allows managing governments and associated profiles. It is possible to add a government with an Ethereum address, to add a profile for a given government and to retrieve profiles associated with a government.",
                    "technologies_Gouvernement_Manager": "Solidity,Ethereum,JavaScript,Smart Contract,Government Management,Profile Management,Web3,Truffle,Ganache",
                    "Projets_Reseau_de_neurones": "Neural Network Projects",
                    "description_Projets_Reseau_de_neurones": "This project contains two programs that use neural networks for classification and regression. The first program uses a neural network to classify whether a patient has cancer or not based on a database. The second program also uses a neural network to predict the price of a house using regression.",
                    "Voir_tous_mes_projets": "See all my projects",
                    "technologies_Projets_Reseau_de_neurones": "Python,NumPy,Pandas,scikit-learn,TensorFlow,PyTorch",
                    "Home": "Home",
                    "Projects": "Projects",
                    "About": "About",
                    "Contact": "CONTACT",
                    "Hello, I am": "Hello, I am",
                    "Iliass": "Iliass",
                    "Developer": "A web developer passionate about creating websites and efficient applications. I am always looking for new technologies to improve my skills.",

                    "Me contacter": "Contact me",
                    "Nom": "Name",
                    "Email": "Email",
                    "Message": "Message",
                    "Envoyer": "Send",
                    "Ce champ est requis": "This field is required",
                    "Email envoyé avec succès": "Email successfully sent",
                    "Erreur lors de l'envoi de l'email": "Error when sending the email",
                    "Copied ! 📋": "Copied ! 📋",

                    "Mes compétences": "My skills",
                    "Ce que je maîtrise": "What I master",
                    "Langages de programmation": "Programming languages",



                    "Frameworks & Libraries": "Frameworks & Libraries",
                    "Base de données": "Databases",
                    "Outils & Technologies": "Tools & Technologies",

                    "Télécharger CV": "Download CV",
                    "À propos de moi": "About me",
                    "Ma présentation": "My presentation",
                    "Expérience": "Experience",
                    "Plus de 30 projets": "Over 30 projects",
                    "Diplôme": "Degree",
                    "Master en Informatique": "Master in Computer Science",
                    "Langages": "Languages",
                    "Multiples langages de programmation": "Many programming languages",
                    "about_me_description": "I am a <b>{{age}} year old</b> developer who studied at <b>Paris 8 University</b>. I obtained a <b>Master's degree in Computer Science</b> at Paris 8 University, where I acquired strong skills in programming and software development. I have completed a multitude of projects in different <b>programming languages</b>, which has allowed me to develop versatile expertise. I have also contributed to more than <b>30 projects</b> on GitHub, demonstrating my passion for development and continuous learning.",

                    "Qualifications": "Qualifications",
                    "- Mon parcours personnel -": "- My personal journey -",
                    "Ingénieur développeur logiciel polyvalent (alternance)": "Versatile software developer engineer (apprenticeship)",
                    "1 an": "1 year",
                    "Développeur Web Full Stack (stage)": "Full Stack Web Developer (internship)",
                    "4 mois": "4 months",
                    "Master Informatique": "Master in Computer Science",
                    "Université Paris 8": "Paris 8 University",
                    "Licence Informatique": "Bachelor's degree in Computer Science",
                    "Site en travaux": "Site under construction",
                    "Ce site est actuellement en travaux pour les utilisateurs de": "This site is currently under construction for users of",
                    "veuillez utiliser un autre navigateur pour le moment.": "please use another browser for the moment.",
                    "technologies_Angular_TicketGestion": "AngularJS,HTML,CSS,Todo List,Ticket Manager,TypeScript",
                    "description_Angular_TicketGestion": "This is a simple web application for creating and managing tickets. With this application, you can easily create new tickets with unique IDs, titles, descriptions, and categories.",
                    "Voir tous mes projets": "See all my projects",
                }
            },
            fr: {
                translations: {
                    "Projets": "Projets",
                    "Mcirosoft": "Mcirosoft",
                    "description_Mcirosoft": "Ce template de site web offre une grande variété de pages, dont une page d'accueil, une présentation de services, des articles, un formulaire de candidature, un forum et bien plus encore. Le front-end est hébergé sur Github pour une expérience de navigation fluide et rapide.",
                    "Webcam_Run": "Webcam Run",
                    "description_Webcam_Run": "Ce jeu est un jeu de plateforme interactif dans lequel le joueur contrôle un personnage en utilisant une webcam pour suivre les mouvements de sa tête. Il doit éviter les obstacles tout en courant contre la montre pour atteindre un coffre final. Le jeu utilise une combinaison de bibliothèques Python pour offrir une expérience immersive et amusante.",
                    "MediaTwitter": "MediaTwitter",
                    "description_MediaTwitter": "Mediatwitter est une application web conçue pour afficher des tweets multimédias de Twitter. Elle vous permet de trouver facilement des médias ou des utilisateurs grâce à une barre de recherche efficace et de suggérer des médias pour des raccourcis futurs.",
                    "Mysearch": "Mysearch",
                    "description_Mysearch": "Un moteur de recherche web développé en PHP qui vous permet d'indexer des URLs et de les rechercher en fonction de mots-clés pertinents. Le moteur de recherche utilise le poids des mots-clés pour classer les résultats de recherche.",
                    "Visualise_Image": "Visualise Image",
                    "description_Visualise_Image": "Un projet permettant de visualiser des images à travers une carte Google et une timeline interactive en utilisant les métadonnées de localisation et de date de prise de vue stockées dans une base de données MYSQL.",
                    "Uno": "Uno",
                    "description_Uno": "Ce jeu Uno en ligne de commande a été développé en langage C. Il utilise les concepts de base du langage C, tels que les tableaux, les structures, les pointeurs, les boucles et les conditions pour simuler le jeu.",
                    "Angular_TicketGestion": "Angular-TicketGestion",
                    "description_Angular_TicketGestion": "Il s'agit d'une application web simple permettant de créer et de gérer des tickets. Avec cette application, vous pouvez facilement créer de nouveaux tickets avec des identifiants uniques, des titres, des descriptions et des catégories.",
                    "Tetris_Plateau": "Tetris-Plateau",
                    "description_Tetris_Plateau": "Le Tetris est un jeu de puzzle dans lequel le joueur doit placer des blocs de différentes formes dans une matrice afin de former des lignes complètes. Le but est de garder le plateau le plus longtemps possible non plein.",
                    "Gouvernement_Manager": "Gouvernement Manager",
                    "description_Gouvernement_Manager": "Ce contrat permet de gérer des gouvernements et des profils associés. Il est possible d'ajouter un gouvernement avec une adresse Ethereum, d'ajouter un profil pour un gouvernement donné et de récupérer les profils associés à un gouvernement.",
                    "Projets_Reseau_de_neurones": "Projets Reseau-de-neurones",
                    "description_Projets_Reseau_de_neurones": "Ce projet contient deux programmes qui utilisent des réseaux de neurones pour faire de la classification et de la régression. Le premier programme utilise un réseau de neurones pour classifier si un patient est atteint d'un cancer ou non en se basant sur une base de données. Le deuxième programme utilise également un réseau de neurones pour prédire le prix d'une maison en utilisant la régression.",
                    "Voir_tous_mes_projets": "Voir tous mes projets",
                    "technologies_Mcirosoft": "React,SCSS,Laravel,PostgreSQL,Forum,Dashboard,Page de services,Page de contact,Page de candidature,Page de connexion,Token d'authentification,Formulaire automatique,Téléchargements de PDF",
                    "technologies_Webcam_Run": "Python,Youtube-API,MYSQL,Arcade,OpenCV2,Tkinter,Pyscreenrec,Tiled,Pillow,Jeu de plateforme,Contre la montre,Webcam,Gameplay enregistré,Partage de gameplay,Partage de score",
                    "technologies_MediaTwitter": "ReactJS,NodeJS,Mysql,Scss,React,Moment,Toastify,Linkify,Paginate,Hook-form,Twitter API,Axios,React-router-dom,Base de données,Recherche,Mailform",
                    "technologies_Mysearch": "PHP,MySQL,javascript,HTML,CSS,Nuage de mots-clés,Moteur de recherche,Indexation des URLs,Recherche par mots-clés,Affichage des informations d'indexation",
                    "technologies_Visualise_Image": "PHP,HTML,CSS,JavaScript,API Google Maps,Librairie vis.js,Base de données MYSQL,Librairie exif",
                    "technologies_Uno": "C,Jeu de cartes,Jeu de cartes multijoueur,Terminal,Jeu de cartes en réseau local",
                    "technologies_Angular_TicketGestion": "AngularJS,HTML,CSS,Todo List,Gestionnaire de tickets,TypeScript",
                    "technologies_Tetris_Plateau": "C++,Tetris,Plateau,Jeu de puzzle",
                    "technologies_Gouvernement_Manager": "Solidity,Ethereum,JavaScript,Smart Contract,Gestion des gouvernements,Gestion des profils,Web3,Truffle,Ganache",
                    "technologies_Projets_Reseau_de_neurones": "Python,NumPy,Pandas,scikit-learn,TensorFlow,PyTorch",
                    "Home": "Accueil",
                    "Projects": "Projets",
                    "About": "À propos",
                    "Contact": "CONTACT",
                    "Hello, I am": "Bonjour, Je suis",
                    "Iliass": "Iliass",
                    "Developer": "Développeur web passionné par la création de sites web et d'applications performantes.  Je suis toujours à la recherche de nouvelles technologies pour améliorer mes compétences.",

                    "Me contacter": "Me contacter",
                    "Nom": "Nom",
                    "Email": "Email",
                    "Message": "Message",
                    "Envoyer": "Envoyer",
                    "Ce champ est requis": "Ce champ est requis",
                    "Email envoyé avec succès": "Email envoyé avec succès",
                    "Erreur lors de l'envoi de l'email": "Erreur lors de l'envoi de l'email",
                    "Copied ! 📋": "Copié ! 📋",

                    "Mes compétences": "Mes compétences",
                    "Ce que je maîtrise": "Ce que je maîtrise",
                    "Langages de programmation": "Langages de programmation",

                    "Frameworks & Libraries": "Frameworks & Librairies",
                    "Base de données": "Base de données",
                    "Outils & Technologies": "Outils & Technologies",
                    "Télécharger CV": "Télécharger CV",
                    "À propos de moi": "À propos de moi",
                    "Ma présentation": "Ma présentation",
                    "Expérience": "Expérience",
                    "Plus de 30 projets": "Plus de 30 projets",
                    "Diplôme": "Diplôme",
                    "Master en Informatique": "Master en Informatique",
                    "Langages": "Langages",
                    "Multiples langages de programmation": "Multiples langages de programmation",

                    "about_me_description": "Je suis un développeur de <b>{{age}} ans</b> ayant étudié à l'<b>université Paris 8</b>. J'ai obtenu un <b>Master en Informatique</b> à l'Université Paris 8, où j'ai acquis de solides compétences en programmation et en développement logiciel. J'ai réalisé une multitude de projets dans différents <b>langages de programmation</b>, ce qui m'a permis de développer une expertise polyvalente. J'ai également contribué à plus de <b>30 projets</b> sur GitHub, témoignant de ma passion pour le développement et l'apprentissage continu.",

                    "Qualifications": "Qualifications",
                    "- Mon parcours personnel -": "- Mon parcours personnel -",
                    "Ingénieur développeur logiciel polyvalent (alternance)": "Ingénieur développeur logiciel polyvalent (alternance)",
                    "1 an": "1 an",
                    "Développeur Web Full Stack (stage)": "Développeur Web Full Stack (stage)",
                    "4 mois": "4 mois",
                    "Master Informatique": "Master Informatique",
                    "Université Paris 8": "Université Paris 8",
                    "Licence Informatique": "Licence Informatique",
                    "Site en travaux": "Site en travaux",
                    "Ce site est actuellement en travaux pour les utilisateurs de": "Ce site est actuellement en travaux pour les utilisateurs de",
                    "veuillez utiliser un autre navigateur pour le moment.": "veuillez utiliser un autre navigateur pour le moment.",
                    "Voir tous mes projets": "Voir tous mes projets",

                }
            }
        },
        fallbackLng: "en",
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;
